p,
.form-label,
.accordion-body,
.form-check-label {
    color: rgb(122, 122, 122);
    font-weight: 400;
    font-family: Roboto, sans-serif;
    font-size: 16px;
}

h1,
h2,
h3,
h4 {
    color: #223459;
}

.nav-link,
.nav-link a {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}

.nav-link:hover,
.nav-link:hover a,
.nav-underline .nav-link.active,
.nav-link.active a {
    color: #50e2f2;
}

.main-block {
    display: flex;
    gap: 24px;
}

.main-block.reverse {
    flex-direction: row-reverse;
}

.main-block .youtube,
.main-block .youtube-preview {
    width: 560px;
}

@media (max-width: 1000px) {
    .main-block,
    .main-block.reverse {
        flex-direction: column;
    }

    .main-block .youtube,
    .main-block .youtube-preview {
        width: 100%;
    }
}

.list tbody tr :nth-child(odd) td {
    background-color: #f2f2f2;
}
.copyButtonDiv .copyButton {
    display: none;
    position: absolute;
    right: 5px;
    top: 8px;
    cursor: pointer;
}

.copyButtonDiv {
    position: relative;
}

.copyButtonDiv:hover .copyButton {
    display: inline-block;
}
.copyButton:active {
    transform: scale(0.8);
}
.printButtonDiv {
    position: relative;
}
.printButton {
    display: none;
    position: absolute;
    right: 35px;
    top: 8px;
    cursor: pointer;
}

.printButtonDiv:hover .printButton {
    display: block;
}

.printButton:active {
    transform: scale(0.8);
}

.page-part {
    border: 1px dashed grey;
    box-sizing: border-box;
    display: inline-block;
    padding: 10px;
    cursor: pointer;
}
.page-last-selected {
    background-color: #8ecbff;
}
.page-text {
    opacity: 0.1;
}
.page-part:hover {
    background-color: #dcdcdc;
}
.page-part:hover .page-text {
    opacity: 1;
}

.page-part:active {
    transform: scale(0.9);
}

a,
.link {
    color: #3bacd9;
}
a:visited {
    color: #3bacd9;
}
a:hover,
.link:hover {
    color: #f2a649;
}

.register-modal .form-label, .register-modal .form-check-label {
    color: black;
}
.register-modal .form-check-input {
    appearance: auto;
}
.register-modal .form-control {
    border: 1px solid #6e6e6e;
}
